import { useEffect, useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import axios from 'axios'
import useStorage from 'src/sdk/hooks/useStorage'
import InputWithSuggestions from 'src/components/common/Input/InputWithSuggestions'

import CollectionItens from './components/CollectionItens'

interface PersonalFavoriteStoreProps {
  blockInput: boolean
}

export const PersonalFavoriteStore = ({
  blockInput,
}: PersonalFavoriteStoreProps) => {
  const { updateUserDecathlon } = useAccountContext()
  const { storageGetItem } = useStorage()
  const [stores, setStores] = useState<Array<{ id: string; value: string }>>([])

  const [allStores, setAllStores] = useState([])

  const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

  const handleStoreList = async (store: any) => {
    const info = {
      third_num: `${store.id}`,
      third_type: '7',
      third_sub_num: `${store.id}`,
    }

    const response = await axios.post('/api/account/updateFavoriteStore', {
      data: info,
    })

    if (response.status === 200) {
      setStores([{ id: store.id, value: store.value }])

      updateUserDecathlon()
    }
  }

  useEffect(() => {
    const getFavoriteStore = () => {
      return JSON.parse(storageUserDecathlon).getFavoriteStore
    }

    const fetchData = async () => {
      const [PRACTICE, response] = await Promise.all([
        getFavoriteStore(),
        await axios.get(
          `https://decathlonstore.myvtex.com/api/dataentities/SL/search?_fields=address,name,openingHours,pageLink,phone,store_id,status_active&_where=status_active=true`,
          {
            headers: {
              'rest-range': 'resources=0-500',
            },
          }
        ),
      ])

      const storeInfo = response.data.map(
        (store: { name: string; store_id: string }) => {
          const { name } = store
          const storeId = store.store_id

          return { id: storeId, value: name }
        }
      )

      setAllStores(storeInfo)

      const STORESelected = response?.data?.find((obj: any) => {
        return Number(obj.store_id) === Number(PRACTICE?.third_num)
      })

      if (STORESelected) {
        setStores([{ id: STORESelected.store_id, value: STORESelected.name }])
      }
    }

    fetchData()
  }, [storageUserDecathlon])

  return (
    <div className="block sm:flex-col sm:flex-row py-4 w-full xs:justify-around pb-10 border-b border-[#E1E4E7]">
      <span>Loja Favorita</span>

      {allStores.length > 0 && blockInput && (
        <InputWithSuggestions
          classContainer="w-full"
          classInput="w-full  border-neutral04  border-[1px]  mt-2"
          setOption={handleStoreList}
          optionList={allStores}
          blockInput={blockInput}
        />
      )}

      <CollectionItens
        list={stores}
        removeOption={() => {}}
        isShowDelete={false}
        blockInput={blockInput}
      />
    </div>
  )
}
