import type { RouteComponentProps } from '@reach/router'
import { useState, useContext, useEffect } from 'react'
import { AccountContext } from 'src/components/account/context'
import { useLocation } from '@reach/router'
import {
  makeEventTrack,
  makeVirtualPageEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'

import { PersonalData } from '../../../account/Profile/PersonalData'
import { Addresses } from '../../../account/Profile/Tabs/Addresses'
import { Communication } from '../../../account/Profile/Tabs/Communication'

const tabs = [
  { label: 'Dados pessoais', id: 'tab1' },
  { label: 'Endereços', id: 'tab2' },
  { label: 'Comunicação', id: 'tab3' },
]

const Profile = (_: RouteComponentProps) => {
  const { userDecathlon } = useContext(AccountContext)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const tabId = tabs.find((tab) => tab.id === searchParams.get('tab'))
    ? searchParams.get('tab')
    : 'tab1'

  const [activeTab, setActiveTab] = useState(tabId)

  function trackVirtualEvent(prevTab: string | null, currentTab: string) {
    const event = makeVirtualPageEvent(
      {
        ...window,
        location: { href: `${document.location.href}?tab=${currentTab}` },
      },
      {
        href: `${document.location.href}?tab=${prevTab ?? ''}`,
      }
    )

    sendEvent(event)
  }

  function trackClickEvent(label: string) {
    const event = makeEventTrack({
      eventAction: label,
      eventPage: 'my account - Gerenciar perfil',
    })

    sendEvent(event)
  }

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    window.decathlonAnalytics = window.decathlonAnalytics || {}

    window.decathlonAnalytics = {
      user_info: {
        user_id: userDecathlon?.getUserProfile.claims.email,
        login_status: 'Logado',
        source_user_id: 'Vtex',
      },
    }
  }, [userDecathlon])

  return (
    <section className="md:max-w-[750px]">
      <div className="flex gap-2 overflow-x-auto max-w-[calc(100vw-2rem)] scrollbar-hide">
        {tabs.map(({ label, id }, i) => (
          <button
            key={i}
            onClick={() => {
              setActiveTab((oldTab) => {
                trackVirtualEvent(oldTab, id)

                return id
              })
              trackClickEvent(label)
            }}
            className="px-6 py-3 font-semibold text-[#15181B] border border-solid border-[#C3C9CF] rounded-full aria-[current=page]:border-[#3643BA] aria-[current=page]:text-[#3643BA] whitespace-nowrap"
            aria-current={id === activeTab && 'page'}
          >
            {label}
          </button>
        ))}
      </div>

      <div>
        {activeTab === 'tab1' && userDecathlon && (
          <PersonalData userDecathlon={userDecathlon} />
        )}
        {activeTab === 'tab2' && <Addresses />}
        {activeTab === 'tab3' && <Communication />}
      </div>
    </section>
  )
}

export default Profile
