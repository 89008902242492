import Icon from 'src/components/ui/Icon'

const EmptyWishlist = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-16 mb-14 px-8 gap-4 text-[0.875rem] leading-[1.3125rem] text-center md:border md:border-solid md:border-[#E1E4E7] md:rounded md:flex-row md:text-left md:py-8 md:mt-[26px]">
      <div className="border border-solid border-[#E1E4E7] rounded-full p-4">
        <Icon name="BlueWishlistHeart" width="33" height="32" />
      </div>
      <div className="flex flex-col gap-4 md:max-w-[204px] md:gap-2">
        <p className="text-[#15181B] font-medium">
          Crie sua lista de favoritos.
        </p>
        <p className="text-[#687787] md:text-[0.75rem] md:leading-[1.125rem]">
          Explore as coleções e salve os itens favoritos em sua lista.
        </p>
      </div>
    </div>
  )
}

export default EmptyWishlist
