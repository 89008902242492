import { Link } from 'gatsby'

import redirectIcon from './redirect.svg'

type ProfileInfoItem = {
  text: string
  iconGray: any
  icon: any
  hasInfo: boolean
  link: string
}

export const ProfileCompletionCard = ({
  profileInfo,
  hasEmptyInfo,
  handleInclude,
  trackEvent,
}: {
  profileInfo: ProfileInfoItem[]
  hasEmptyInfo: boolean
  handleInclude: () => string
  trackEvent: (event: string) => void
}) => (
  <div className="flex flex-col">
    <div className="h-[35px] w-full" />
    <div className="p-7 border border-[#E1E4E7] h-full flex flex-col rounded-lg sm:max-w-[281px] max-w-[348px]">
      <h4 className="font-inter text-base font-normal leading-6 tracking-normal text-left text-[#15181B]">
        Complete seu Perfil e obtenha uma experiência de compra{' '}
        <strong className="font-inter text-base font-normal text-left text-[#15181B] border-b">
          mais personalizada
        </strong>
      </h4>
      <ul className="flex flex-col mt-[64px]">
        {profileInfo.map((info, index) => (
          <li
            className={`flex items-center justify-start sm:pt-[5px] sm:pb-[5px] pt-[8px] pb-[8px] cursor-default ${
              info.hasInfo ? 'text-[#01684C]' : 'text-[#687787]'
            } mt-[1px] mb-[1px] border-l-[6px] ${
              info.hasInfo ? 'border-[#02BE8A]' : 'border-[#E1E4E7]'
            }`}
            key={index}
          >
            <Link
              onClick={() => trackEvent(`Completar perfil - ${info.text}`)}
              className="flex"
              to={info.link}
            >
              <div className="ml-[6px] mr-[6px]">
                <img
                  className="h-[24px] w-[24px]"
                  src={info.hasInfo ? info.icon : info.iconGray}
                  alt=""
                />
              </div>
              <p className="font-inter text-sm font-normal text-left">
                {info.text}
              </p>
            </Link>
          </li>
        ))}
      </ul>
      {hasEmptyInfo && (
        <a
          href={handleInclude()}
          className="mt-10 sm:mt-auto min-h-[24px] font-inter text-[16px] font-semibold text-center flex items-center justify-start gap-[8px] text-primaryBlue text-sm"
        >
          Incluir Informações <img className="" alt="" src={redirectIcon} />
        </a>
      )}
    </div>
  </div>
)
