import type { RouteComponentProps } from '@reach/router'
import { useEffect } from 'react'
import { useAccountContext } from 'src/components/account/context'
import { Wishlist as WishlistSection } from 'src/components/sections/Account'
import 'src/styles/pages/myaccount.scss'

function Wishlist(_: RouteComponentProps) {
  const { userDecathlon } = useAccountContext()

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    window.decathlonAnalytics = window.decathlonAnalytics || {}

    window.decathlonAnalytics = {
      user_info: {
        user_id: userDecathlon?.getUserProfile.claims.email,
        login_status: 'Logado',
        source_user_id: 'Vtex',
      },
    }
  }, [userDecathlon])

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center py-4 mx-auto md:flex-row md:items-start max-w-7xl md:py-0">
        <div className="flex flex-col items-center w-full md:max-w-5xl md:items-unset">
          <WishlistSection />
        </div>
      </div>
    </div>
  )
}

export default Wishlist
