import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'

type AddressControlsMobileProps = {
  setShowControlsMobile: React.Dispatch<React.SetStateAction<boolean>>
  handleEdit: () => void
  setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  trackEvent: (eventAction: string, eventPage: string) => void
}

const AddressControlsMobile = ({
  setShowControlsMobile,
  handleEdit,
  setShowDeleteConfirmation,
  trackEvent,
}: AddressControlsMobileProps) => {
  return (
    <div className="fixed top-0 left-0 bg-black bg-opacity-40 z-[10000] w-full h-full flex justify-center items-end">
      <div className="w-full  h-[230px] bg-white flex flex-col shadow-dropCard">
        <div className="justify-between items-center  border-b border-neutral04 p-4 flex">
          <span>Opções</span>
          <button onClick={() => setShowControlsMobile(false)}>
            <CloseButtonIcon />
          </button>
        </div>

        <div className="flex flex-col p-8 gap-3">
          <button
            onClick={handleEdit}
            className="w-full h-[48px] rounded-round bg-black text-white font-inter text-base font-semibold hover:bg-[#2a3036] active:bg-[#3e4751]"
          >
            Editar endereço
          </button>
          <button
            onClick={() => {
              setShowDeleteConfirmation(true)
              trackEvent('Deletar Endereço', 'my account - Gerenciar perfil')
            }}
            className="w-full h-[48px] border border-[#FF2934] rounded-round text-restructure-error font-inter text-base font-semibold hover:bg-[#f3f3f3] active:bg-[#e8e8e9]"
          >
            Excluir endereço
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddressControlsMobile
