import Button from 'src/components/ui/Button'
import { maskCurrency } from 'src/utils/convertCurrency'
import useWishlist from 'src/hooks/useWishlist'
import Link from 'src/components/ui/Link'
import type { WishlistProduct } from 'src/typings/contexts/wishlist/wishlist-product'
import { useWishlistContext } from 'src/components/contexts/wishlist-context'

type WishlistProductCardProps = {
  product: WishlistProduct
}

const WishlistProductCard = ({ product }: WishlistProductCardProps) => {
  const {
    productName,
    items: [
      {
        images: [{ imageUrl, imageText }],
        offer: { hasDiscount, listPrice, price },
      },
    ],
    productId,
    linkText,
  } = product

  const { setUserWishlist, setWishlistId } = useWishlistContext()
  const { getWishlist, removeProductFromWishlist } = useWishlist()

  return (
    <div className="flex flex-col md:flex-row md:w-full md:justify-between md:items-center">
      <div className="flex gap-4 py-6 md:py-[1.125rem]">
        <div className="bg-[#F7F8F9] opacity-100">
          <img
            src={imageUrl}
            alt={imageText}
            width={104}
            height={104}
            className="mix-blend-multiply rounded"
          />
        </div>
        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-col justify-between h-full text-[#15181B] md:gap-2 md:justify-start">
            <p className="text-[0.75rem] leading-[1.125rem] max-h-[40px] text-ellipsis overflow-hidden md:text-base">
              {productName}
            </p>
            <div className="flex flex-col gap-2 md:flex-row-reverse md:justify-end">
              {hasDiscount === true && (
                <p className="text-[1rem] leading-[1.3125rem] text-[#CC212A] line-through">
                  {maskCurrency(listPrice)}
                </p>
              )}
              <p className="flex gap-0.5 items-baseline text-base font-semibold md:gap-2">
                {maskCurrency(price)}
                <span className="text-[0.75rem] leading-[1.125rem]">un.</span>
              </p>
            </div>
          </div>
          <Button
            onClick={() => {
              removeProductFromWishlist(productId).then(() => {
                getWishlist().then((wishlistData) => {
                  setUserWishlist(wishlistData?.data?.products ?? [])
                  setWishlistId(wishlistData?.data?.id)

                  return wishlistData?.data?.products
                })
              })
            }}
            className="text-[0.875rem] leading-[1.3125rem] text-primaryBlue font-semibold h-fit md:w-fit"
          >
            Excluir
          </Button>
        </div>
      </div>
      <Link
        className="h-8 w-[150px] flex items-center justify-center mb-6 bg-[#3643BA] !text-white text-[0.875rem] leading-[1.3125rem] font-semibold rounded-full hover:bg-[#323894] hover:!no-underline md:mb-0"
        href={`/${linkText}/p`}
      >
        Comprar agora
      </Link>
    </div>
  )
}

export default WishlistProductCard
