/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from 'src/components/ui/Image'

import { formatMoney } from '../../../utils'

import './styles.scss'

interface OrderProductsProps {
  items: any
  sellers: any
}

function OrderProducts({ items, sellers }: OrderProductsProps) {
  return (
    <section className="products">
      <div className="productsHeader">
        <span>Produto</span>
        <span className="productsHeaderPrice">Preço</span>
        <span>Qtd</span>

        <span>Subtotal</span>
      </div>

      {items?.map(
        (
          { price, name, imageUrl, quantity, measurementUnit }: any,
          index: number
        ) => (
          <article key={index} className="product">
            <div className="productInfo">
              <Image
                className="productImage"
                src={imageUrl}
                alt={name}
                width={50}
                height={50}
                loading="lazy"
              />
              <p>
                <strong>{name}</strong>
                <span>{sellers[0].name}</span>
              </p>
            </div>
            <span className="productPrice">{formatMoney(price)}</span>
            <span className="productQuantity">
              {quantity}
              {measurementUnit}
            </span>
            <span className="productSubtotal">{formatMoney(price)}</span>
          </article>
        )
      )}
    </section>
  )
}

export default OrderProducts
