import axios from 'axios'
import { useEffect, useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import type { OrderItem, Items } from 'src/components/account/Orders/types'

export const getLastOrder = (allOrders: OrderItem[]) => {
  if (!allOrders) {
    return null
  }

  const sortedObjects = [...allOrders].sort((a: OrderItem, b: OrderItem) => {
    const date1 = new Date(b.creationDate)
    const date2 = new Date(a.creationDate)

    return date1.valueOf() - date2.valueOf()
  })

  return sortedObjects[0]
}

export const getBestProduct = (allProducts: Items[] | undefined) => {
  if (!allProducts) {
    return null
  }

  const sortedProducts = [...allProducts].sort(
    (a: Items, b: Items) => b.price - a.price
  )

  return sortedProducts[0]
}

export const useOrderData = () => {
  const { isLoggedIn } = useAccountContext()
  const [order, setOrder] = useState<OrderItem | null>(null)
  const [product, setProduct] = useState<Items | null>(null)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    const fetchOrderData = async () => {
      try {
        const { data } = await axios.post('/api/getOrderList', { page: 1 })
        const allOrders = data.response.data.DktApp_GetOrderList.list

        if (allOrders) {
          const lastOrder = getLastOrder(allOrders)
          const bestProduct = getBestProduct(lastOrder?.items)

          setOrder(lastOrder)
          setProduct(bestProduct)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoader(false)
      }
    }

    fetchOrderData()
  }, [isLoggedIn])

  return { order, product, loader }
}
