import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import type { SetStateAction } from 'react'
import { useState } from 'react'
import { ArrowExternalLink } from 'src/components/Icons/ArrowExternalLink'
import { logoutUser } from 'src/utils/account/logoutUser'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import HelpModal from './HelpModal'

const BASE_ROUTE = '/account'

const routes = [
  {
    label: 'Home',
    url: BASE_ROUTE,
  },
  {
    label: 'Minhas compras',
    url: `${BASE_ROUTE}/orders`,
  },
  {
    label: 'Meus favoritos',
    url: `${BASE_ROUTE}/wishlist`,
  },
  {
    label: 'Trocas e devoluções',
    url: `${BASE_ROUTE}/exchanges`,
  },
  {
    label: 'Gerenciar perfil',
    url: `${BASE_ROUTE}/profile`,
  },
]

interface Props {
  onCloseModal?: (value: SetStateAction<boolean>) => void
}

const SideMenu = ({ onCloseModal }: Props) => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const closeModal = onCloseModal ? () => onCloseModal(false) : () => {}
  const { pathname } = useLocation()

  function trackEvent(e: React.MouseEvent<HTMLAnchorElement>) {
    const event = makeEventTrack({
      eventAction: e.currentTarget.innerText,
      eventPage: pathname.includes('account') ? 'my account' : pathname,
    })

    sendEvent(event)
  }

  return (
    <>
      <aside className="flex flex-col justify-between flex-1 md:flex-none">
        <ul className="text-lg md:text-sm md:mb-8">
          {routes.map(({ label, url }, i) => (
            <li key={i} className="py-3 md:py-1 last:hidden md:last:block">
              <Link
                to={url}
                onClick={(e) => {
                  closeModal()
                  trackEvent(e)
                }}
                className="aria-[current=page]:font-bold flex items-center gap-1"
                state={{ prevPath: pathname }}
              >
                {label}
                {!url.includes(BASE_ROUTE) && <ArrowExternalLink />}
              </Link>
            </li>
          ))}

          <div className="flex flex-col mt-8">
            <button
              className="my-1 flex items-start justify-start gap-1"
              onClick={() => setIsHelpModalOpen(true)}
            >
              Preciso de ajuda
              <ArrowExternalLink size="11" />
            </button>

            <button
              className="my-1 flex items-center justify-start"
              onClick={() => logoutUser({ pathname })}
            >
              Desconectar
            </button>
          </div>
        </ul>
      </aside>
      {isHelpModalOpen && (
        <HelpModal
          isHelpModalOpen={isHelpModalOpen}
          setIsHelpModalOpen={setIsHelpModalOpen}
        />
      )}
    </>
  )
}

export default SideMenu
