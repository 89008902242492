export const SmallLogo = () => (
  <svg
    width="91"
    height="13"
    viewBox="0 0 91 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8132 11.05H34.2872V9.074H30.2769V7.4165H33.8287V5.577H30.2769V3.9195H34.2872V1.95H27.8132V11.05ZM42.3216 7.1305C41.384 8.5475 40.4738 9.139 39.3036 9.139C37.7843 9.139 36.9015 8.125 36.9015 6.3635C36.9015 4.693 37.7159 3.861 38.9682 3.861C39.7963 3.861 40.4807 4.212 40.6928 5.382H43.1565C42.8896 3.1525 41.3909 1.768 38.9956 1.768C36.2171 1.768 34.3899 3.6335 34.3899 6.4935C34.3899 9.373 36.2171 11.232 39.2214 11.232C41.1856 11.232 42.5201 10.452 43.4029 9.399H46.7631V11.05H49.2131V1.95H45.7365L42.3216 7.1305ZM46.7631 7.5725H44.5937L46.7631 4.225V7.5725ZM22.5573 1.95H18.9576V11.05H22.5573C25.4111 11.05 27.252 9.2625 27.252 6.5C27.252 3.7375 25.4111 1.95 22.5573 1.95ZM22.5231 9.074H21.4213V3.9195H22.5231C23.9466 3.9195 24.7473 4.875 24.7473 6.5C24.7473 8.1185 23.9466 9.074 22.5231 9.074ZM77.4908 1.768C74.5959 1.768 72.6523 3.6335 72.6523 6.5C72.6523 9.3665 74.5959 11.232 77.4908 11.232C80.3924 11.232 82.3292 9.3665 82.3292 6.5C82.3292 3.6335 80.3924 1.768 77.4908 1.768ZM77.4908 9.139C76.0673 9.139 75.1708 8.2355 75.1708 6.5C75.1708 4.7645 76.0673 3.861 77.4908 3.861C78.9211 3.861 79.8107 4.7645 79.8107 6.5C79.8107 8.2355 78.9211 9.139 77.4908 9.139ZM49.829 3.9195H52.2448V11.05H54.7085V3.9195H57.1243V1.95H49.829L49.829 3.9195ZM88.6184 1.95V6.9615L85.443 1.95H82.8904V11.05H85.2719V5.8305L88.5774 11.05H91V1.95L88.6184 1.95ZM69.1621 1.95H66.6984V11.05H72.8782V9.0805H69.1621V1.95ZM63.523 5.421H60.2039V1.95H57.7402V11.05H60.2039V7.384H63.523V11.05H65.9867V1.95H63.523V5.421Z"
      fill="#3643BA"
    />
    <path
      d="M12.0046 0C6.65473 0 0 5.2574 0 9.61465C0 11.865 1.81992 13 4.22359 13C5.98857 13 8.1244 12.3869 10.1847 11.2062V2.51129C9.63527 3.40491 7.05305 7.00552 4.97903 8.92323C3.92142 9.90165 3.08356 10.3256 2.36247 10.3256C1.55208 10.3256 1.1675 9.80381 1.1675 9.0276C1.1675 5.50527 7.41016 0.926242 11.5445 0.926242C13.2476 0.926242 14.3465 1.64375 14.3465 3.03964C14.3465 4.31811 13.4331 5.92273 11.8741 7.40341V10.0973C14.5937 8.05569 16.2213 5.45308 16.2213 3.35273C16.2213 1.14149 14.4083 0 12.0046 0Z"
      fill="#3643BA"
    />
  </svg>
)
