import { useEffect, useState } from 'react'
import { useAccountContext } from 'src/components/account/context'

type ProfileInfoProps = {
  text: string
  iconGray: any
  icon: any
  hasInfo: boolean
  link: string
}

export const useProfileInfo = (PROFILE_INFO: ProfileInfoProps[]) => {
  const [profileInfo, setProfileInfo] = useState(PROFILE_INFO)
  const [hasEmptyInfo, setHasEmptyInfo] = useState(true)
  const { userDecathlon } = useAccountContext()

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    setProfileInfo((oldProfile) =>
      oldProfile.map((item) => {
        if (item.text === 'Meus endereços') {
          return { ...item, hasInfo: userDecathlon?.getAddresses?.length > 0 }
        }

        if (item.text === 'Esportes de paixão e loja') {
          return { ...item, hasInfo: !!userDecathlon?.getSportsPractice }
        }

        if (item.text === 'Minhas comunicações') {
          return {
            ...item,
            hasInfo:
              !!userDecathlon?.getUserProfile.claims.email &&
              !!userDecathlon?.getUserProfile.claims.phone_number,
          }
        }

        if (item?.text === 'Informações básicas') {
          const hasCpf =
            userDecathlon?.getUserProfile?.additional_information?.some(
              (info) => info?.id === 'cpf'
            )

          const verify =
            !!userDecathlon?.getUserProfile?.claims?.birthdate &&
            !!userDecathlon?.getUserProfile?.claims?.family_name &&
            !!userDecathlon?.getUserProfile?.claims?.gender &&
            !!userDecathlon?.getUserProfile?.claims?.given_name &&
            !!hasCpf

          return { ...item, hasInfo: verify }
        }

        return item
      })
    )
  }, [userDecathlon])

  useEffect(() => {
    setHasEmptyInfo(profileInfo.some((info) => !info.hasInfo))
  }, [profileInfo])

  const handleInclude = () => {
    const firstEmpty = profileInfo.find((info) => !info.hasInfo)

    return firstEmpty?.link ?? ''
  }

  return { profileInfo, hasEmptyInfo, handleInclude }
}
