import { useContext, useEffect } from 'react'
import NewCard from 'src/components/NewCard'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import { AccountContext } from '../context'
import Identification from './Identification'
import Cashback from './Cashback'

const AccountHome = () => {
  const { userDecathlon } = useContext(AccountContext)

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    window.decathlonAnalytics = window.decathlonAnalytics || {}

    window.decathlonAnalytics = {
      user_info: {
        user_id: userDecathlon?.getUserProfile.claims.email,
        login_status: 'Logado',
        source_user_id: 'Vtex',
      },
    }
  }, [userDecathlon])

  function trackEvent(eventAction: string) {
    const event = makeEventTrack({
      eventAction,
      eventPage: 'my account',
    })

    sendEvent(event)
  }

  return (
    <div className="mb-[120px]">
      <Identification trackEvent={(event) => trackEvent(event)} />

      <div className="flex flex-col lg:flex-row">
        <NewCard trackEvent={(event) => trackEvent(event)} />

        <Cashback trackEvent={(event) => trackEvent(event)} />
      </div>
    </div>
  )
}

export default AccountHome
