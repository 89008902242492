const CheckSaveIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6869 3.64645C13.8822 3.84171 13.8822 4.15829 13.6869 4.35355L6.35356 11.6869C6.1583 11.8821 5.84171 11.8821 5.64645 11.6869L2.31312 8.35355C2.11786 8.15829 2.11786 7.84171 2.31312 7.64645C2.50838 7.45118 2.82496 7.45118 3.02023 7.64645L6.00001 10.6262L12.9798 3.64645C13.175 3.45118 13.4916 3.45118 13.6869 3.64645Z"
      fill="#3643BA"
    />
  </svg>
)

export default CheckSaveIcon
