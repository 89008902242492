import { Link } from 'gatsby'
import { changeVtexImageSize } from 'src/utils/changeVtexImageSize'

import type { OrderItem, Items } from '../account/Orders/types'
import redirectIcon from './redirect.svg'

type LastOrderProps = {
  order: OrderItem
  product: Items | null | undefined
}

const LastOrder = ({ order, product }: LastOrderProps) => {
  function formatDate(dateString: string): string {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.toLocaleString('pt-BR', { month: 'short' })
    const year = date.getFullYear()

    return `${day} ${month.replace('.', '')}, ${year}`
  }

  function replaceWord(str: string) {
    return str ? str.replace(/\bPagamento\b/g, 'Pag.') : str
  }

  function formatCurrency(valueInCents: number) {
    return valueInCents.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const handleClick = () => {
    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'event_track',
      event_category: 'clique',
      event_action: 'Ver detalhes da última compra',
      event_label: 'my account',
    })
  }

  return (
    <div className="sm:max-w-[262px] max-w-[348px] w-full flex flex-col h-full">
      <h4 className="font-inter text-[16px] font-normal leading-[24px] tracking-normal text-left mb-[14px] text-[#101010]">
        Última compra
      </h4>
      <div className="flex flex-col h-full">
        <div className="relative sm:max-w-[260px] sm:max-h-[260px] max-w-[348px] max-h-[348px] w-full ml-[0.44px] rounded-[8px] mb-[20px] bg-[#F7F8F9] flex items-center justify-center">
          <Link to={`/account/order/${order?.orderId}`} onClick={handleClick}>
            <img
              className="w-full rounded-[8px] bg-neutral02 mix-blend-multiply"
              src={changeVtexImageSize(product?.imageUrl, '400-400')}
              alt={product?.name}
            />
          </Link>

          {order?.items?.length > 1 && (
            <Link
              onClick={handleClick}
              to={`/account/order/${order?.orderId}`}
              className="py-[8px] px-[16px] rounded-[999px] gap-4 font-inter text-[12px] font-normal leading-[18px] tracking-[0px] text-left absolute top-[12px] right-[12px] flex  items-center text-[#101010] bg-[#ffffff]"
            >
              + {order.items?.length - 1} itens
            </Link>
          )}
        </div>
        <div className="text-neutral10">
          <div className="flex justify-between gap-[10px]">
            <p className="font-inter text-[12px] font-normal leading-[18px] tracking-normal text-left ">
              {formatDate(order.creationDate)}
            </p>
            <span className="flex items-center gap-[8px]">
              <div className="w-2 h-2 rounded-full bg-restructure-background-action-1" />
              <p className="font-inter text-[12px] font-normal leading-[18px] tracking-[0px] text-left ">
                {replaceWord(order.timeline.currentStatus.label)}
              </p>
            </span>
          </div>
          <p className="font-inter text-[12px] text-xs font-normal leading-5 tracking-normal text-left mt-[4px]">
            Pedido {order.orderId}
          </p>
          <span className="font-inter block text-[16px] font-semibold leading-[24px] tracking-[0.01em] text-left mt-[8px]">
            {formatCurrency(order.value / 100)}
          </span>
        </div>

        <Link
          to={`/account/order/${order.orderId}`}
          onClick={handleClick}
          className="mt-6 sm:mt-auto min-h-[24px] font-inter text-sm font-semibold  text-center flex items-center justify-start gap-[8px] text-primaryBlue"
        >
          Ver detalhes da compra <img className="" alt="" src={redirectIcon} />
        </Link>
      </div>
    </div>
  )
}

export default LastOrder
