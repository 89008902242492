import type { SVGProps } from 'react'

function ArrowInternalLink(props: SVGProps<SVGSVGElement>) {
  const { width = '7', height = '10', fill = '#3643BA' } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M1.53 9.53L.47 8.47 3.94 5 .47 1.53 1.53.47 6.06 5 1.53 9.53z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ArrowInternalLink
