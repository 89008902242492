import React from 'react'
import { useMobile } from 'src/hooks/useMobile'
import type { StoreType } from 'src/components/sections/Account/Routes/Orders'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

type OrderTabsProps = {
  store: 'physical' | 'online'
  setStore: React.Dispatch<React.SetStateAction<StoreType>>
}

const OrderTabs = ({ store, setStore }: OrderTabsProps) => {
  const { isMobile } = useMobile()

  function handleSetStore(type: 'physical' | 'online') {
    setStore(type)

    const event = makeEventTrack({
      eventPage: 'my account - Minhas compras',
      eventAction: type === 'online' ? 'Online' : 'Loja',
    })

    sendEvent(event)
  }

  return (
    <div
      className={`flex justify-between items-center gap-2 overflow-x-auto scrollbar-hide ${
        isMobile ? 'flex-col gap-4' : ''
      }`}
    >
      <span className="text-2xl font-inter font-medium">Minhas compras</span>
      <div className="flex gap-2">
        <button
          className={`px-6 py-3 font-semibold font-inter border rounded-round active:bg-[#e5f2f8]  ${
            store === 'online'
              ? 'border-primaryBlue text-primaryBlue'
              : 'border-primary text-black'
          } ${isMobile ? 'w-[167.5px]' : ''}`}
          onClick={() => handleSetStore('online')}
        >
          Online
        </button>
        <button
          className={`px-6 py-3 font-semibold font-inter border rounded-round active:bg-[#e5f2f8]  ${
            store === 'physical'
              ? 'border-primaryBlue text-primaryBlue'
              : 'border-primary text-black'
          } ${isMobile ? 'w-[167.5px]' : ''}`}
          onClick={() => handleSetStore('physical')}
        >
          Loja
        </button>
      </div>
    </div>
  )
}

export default OrderTabs
