import NewCardSkeleton from './NewCardSkeleton'
import homeIcon from './home.svg'
import mailIcon from './mail.svg'
import ridingIcon from './riding.svg'
import vectorIcon from './Vector.svg'
import homeActiveIcon from './homeActive.svg'
import mailActiveIcon from './mailActive.svg'
import ridingActiveIcon from './ridingActive.svg'
import vectorActiveIcon from './VectorActive.svg'
import LastOrder from './LastOrder'
import { useProfileInfo } from './hooks/useProfileInfo'
import { useOrderData } from './hooks/useOrderData'
import { ProfileCompletionCard } from './ProfileCompletionCard'

const PROFILE_INFO = [
  {
    text: 'Informações básicas',
    iconGray: vectorIcon,
    icon: vectorActiveIcon,
    hasInfo: true,
    link: '/account/profile',
  },
  {
    text: 'Esportes de paixão e loja',
    iconGray: ridingIcon,
    icon: ridingActiveIcon,
    hasInfo: false,
    link: '/account/profile',
  },
  {
    text: 'Meus endereços',
    iconGray: homeIcon,
    icon: homeActiveIcon,
    hasInfo: false,
    link: '/account/profile?tab=tab2',
  },
  {
    text: 'Minhas comunicações',
    iconGray: mailIcon,
    icon: mailActiveIcon,
    hasInfo: false,
    link: '/account/profile?tab=tab3',
  },
]

type NewCardProps = {
  trackEvent: (trackEvent: string) => void
}

const NewCard = ({ trackEvent }: NewCardProps) => {
  const { profileInfo, hasEmptyInfo, handleInclude } =
    useProfileInfo(PROFILE_INFO)

  const { order, product, loader } = useOrderData()

  if (loader) {
    return (
      <div className="mt-[40px] sm:max-w-[324px] mb-6 sm:mb-0 max-w-[348px] w-full pr-0 flex justify-center sm:justify-start">
        <NewCardSkeleton />
      </div>
    )
  }

  return (
    <div className="mt-[40px] sm:max-w-[324px] mb-6 sm:mb-0 max-w-[348px] w-full pr-0 flex justify-center sm:justify-start">
      {!order ? (
        <ProfileCompletionCard
          profileInfo={profileInfo}
          hasEmptyInfo={hasEmptyInfo}
          handleInclude={handleInclude}
          trackEvent={trackEvent}
        />
      ) : (
        <LastOrder order={order} product={product} />
      )}
    </div>
  )
}

export default NewCard
