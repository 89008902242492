import { useWishlistContext } from 'src/components/contexts/wishlist-context'
import useWishlist from 'src/hooks/useWishlist'
import { useMemo } from 'react'

import EmptyWishlist from './EmptyWishlist'
import WishlistProductCard from './WishlistProductCard'

const Wishlist = () => {
  useWishlist()
  const { userWishlist } = useWishlistContext()

  const handleList = useMemo(() => {
    return userWishlist && !userWishlist?.length ? (
      <EmptyWishlist />
    ) : (
      <div className="flex flex-col divide-y divide-[#E1E4E7]">
        {userWishlist?.map((wishlistProduct, index: number) => {
          return <WishlistProductCard key={index} product={wishlistProduct} />
        })}
      </div>
    )
  }, [userWishlist])

  return (
    <div className="flex flex-col gap-4 mb-16 md:w-full md:gap-[1.375rem] md:mb-14">
      <h2 className="flex items-center justify-center text-2xl text-[#15181B] font-medium md:justify-start">
        Meus favoritos
      </h2>
      {handleList}
    </div>
  )
}

export { Wishlist }

export default Wishlist
