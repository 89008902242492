export const slides = [
  {
    benefits: [
      {
        title: 'Descontos e preços exclusivos em produtos selecionados',
        links: [
          {
            text: 'Confira os produtos',
            to: '/collection/?q=923',
            trackEventText:
              'Descontos e preços exclusivos em produtos selecionados - Confira os produtos',
          },
          {
            text: 'Saiba como funciona',
            to: '/clube',
            trackEventText:
              'Descontos e preços exclusivos em produtos selecionados - Saiba como funciona',
          },
        ],
      },
      {
        title: 'Dicas de Esporte e convites para eventos exclusivos',
        links: [
          {
            text: 'Ative a opção',
            to: '/account/profile?tab=tab3',
            trackEventText:
              'Dicas de Esporte e convites para eventos exclusivos - Ative a opção',
          },
          {
            text: 'Entenda a dinâmica',
            to: '/clube',
            trackEventText:
              'Dicas de Esporte e convites para eventos exclusivos - Entenda a dinâmica',
          },
        ],
      },
      {
        title: 'Desconto de Aniversário',
        links: [
          {
            text: 'Complete seu cadastro para receber o benefício',
            to: '/account/profile',
            trackEventText:
              'Desconto de Aniversário - Complete seu cadastro para receber o benefício',
          },
        ],
      },
    ],
  },
  {
    benefits: [
      {
        title: 'Trocas e devoluções em até 45 dias sem cupom fiscal',
        links: [
          {
            text: 'Saiba como funciona',
            to: '/servicos/trocas-e-devolucoes',
            trackEventText:
              'Trocas e devoluções em até 45 dias sem cupom fiscal - Saiba como funciona',
          },
        ],
      },
      {
        title: 'Serviços exclusivos',
        links: [
          {
            text: 'Encordoamento de raquetes',
            to: '/clube/servicos',
            trackEventText: 'Serviços exclusivos - Encordoamento de raquetes',
          },
          {
            text: 'Serviços de oficina',
            to: '/clube/servicos',
            trackEventText: 'Serviços exclusivos - Serviços de oficina',
          },
        ],
      },
    ],
  },
]
