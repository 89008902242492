import ButtonSpinner from './ButtonSpinner'

function getClassName(isLoading: boolean, variant: Variant) {
  const defaultClassLoading = isLoading
    ? 'bg-restructure-background-neutral-6 text-darkGray'
    : 'bg-black text-white hover:bg-[#2a3036] active:bg-[#3e4751]'

  const deleteClassLoading = isLoading
    ? ''
    : 'hover:bg-[#f3f3f3] active:bg-[#e8e8e9]'

  const defaultClass = `w-full h-[48px] rounded-round font-inter text-base font-semibold  flex justify-center items-center gap-2 ${defaultClassLoading}`

  const deleteClass = `w-full h-[48px] border border-[#FF2934] rounded-round text-restructure-error font-inter text-base font-semibold flex justify-center items-center gap-2 ${deleteClassLoading}`

  const cancel =
    'w-full h-[48px] border rounded-round border-[#C3C9CF] font-inter text-base font-semibold hover:bg-[#f3f3f3] active:bg-[#e8e8e9]'

  const VARIANT = {
    delete: deleteClass,
    default: defaultClass,
    cancel,
  }

  return VARIANT[variant]
}

type Variant = 'default' | 'delete' | 'cancel'

type ButtonLoadingProps = {
  onClick?: () => void
  isLoading?: boolean
  label: string
  variant?: Variant
  type?: 'button' | 'submit'
  disabled?: boolean
}

const ButtonLoading = ({
  onClick,
  isLoading = false,
  label,
  variant = 'default',
  type = 'button',
  disabled = false,
}: ButtonLoadingProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={() => (onClick ? onClick() : null)}
      className={getClassName(isLoading, variant)}
    >
      {isLoading && variant !== 'delete' ? 'Aguarde...' : label}{' '}
      {isLoading && <ButtonSpinner variant={variant} />}
    </button>
  )
}

export default ButtonLoading
