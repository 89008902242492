const ClipEditIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1889_20688)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37034 2.70363C1.71416 2.35981 2.18048 2.16666 2.66671 2.16666L7.33337 2.16666C7.60952 2.16666 7.83337 2.39051 7.83337 2.66666C7.83337 2.9428 7.60952 3.16666 7.33337 3.16666L2.66671 3.16666C2.44569 3.16666 2.23373 3.25445 2.07745 3.41073C1.92117 3.56701 1.83337 3.77898 1.83337 3.99999L1.83337 13.3333C1.83337 13.5543 1.92117 13.7663 2.07745 13.9226C2.23373 14.0789 2.44569 14.1667 2.66671 14.1667H12C12.2211 14.1667 12.433 14.0789 12.5893 13.9226C12.7456 13.7663 12.8334 13.5543 12.8334 13.3333V8.66666C12.8334 8.39051 13.0572 8.16666 13.3334 8.16666C13.6095 8.16666 13.8334 8.39051 13.8334 8.66666V13.3333C13.8334 13.8196 13.6402 14.2859 13.2964 14.6297C12.9526 14.9735 12.4863 15.1667 12 15.1667H2.66671C2.18048 15.1667 1.71416 14.9735 1.37034 14.6297C1.02653 14.2859 0.833374 13.8196 0.833374 13.3333L0.833374 3.99999C0.833374 3.51376 1.02653 3.04744 1.37034 2.70363Z"
        fill="#3643BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 1.75247C13.0909 1.75247 12.8584 1.84879 12.6869 2.02024L6.45153 8.25564L6.02057 9.9795L7.74443 9.54854L13.9798 3.31313C14.1513 3.14168 14.2476 2.90915 14.2476 2.66669C14.2476 2.42422 14.1513 2.19169 13.9798 2.02024C13.8084 1.84879 13.5758 1.75247 13.3334 1.75247ZM11.9798 1.31313C12.3388 0.954147 12.8257 0.752472 13.3334 0.752472C13.8411 0.752472 14.328 0.954147 14.6869 1.31313C15.0459 1.67212 15.2476 2.159 15.2476 2.66669C15.2476 3.17437 15.0459 3.66125 14.6869 4.02024L8.3536 10.3536C8.28953 10.4177 8.20924 10.4631 8.12132 10.4851L5.45465 11.1518C5.28426 11.1944 5.10402 11.1444 4.97983 11.0202C4.85564 10.896 4.80572 10.7158 4.84831 10.5454L5.51498 7.87875C5.53696 7.79084 5.58242 7.71055 5.6465 7.64647L11.9798 1.31313Z"
        fill="#3643BA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1889_20688">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ClipEditIcon
