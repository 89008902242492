import axios from 'axios'
import { useEffect, useState } from 'react'
import type { RouteComponentProps } from '@reach/router'
import { useAccountContext } from 'src/components/account/context'
import OnlineStoreOrders from 'src/components/account/Orders/components/OnlineStoreOrders'
import PhysicalStoreOrders from 'src/components/account/Orders/components/PhysicalStoreOrders'
import type { OnlineStoreOrderType } from 'src/components/account/Orders/types'
import OrderTabs from 'src/components/account/Orders/components/OrderTabs'
import { NoOrdersSkeleton } from 'src/components/account/Orders/components/NoOrdersSkeleton'

import { NoOrders } from '../../../account/Orders/components/NoOrders'

export type StoreType = 'physical' | 'online'

const RenderOrders = ({
  store,
  ListOrders,
  page,
  setValuePage,
}: {
  store: StoreType
  ListOrders: OnlineStoreOrderType
  page: number
  setValuePage: React.Dispatch<React.SetStateAction<number>>
}) => {
  if (store === 'physical') {
    return <PhysicalStoreOrders />
  }

  return (
    <OnlineStoreOrders
      ListOrders={ListOrders}
      setPage={setValuePage}
      page={page}
    />
  )
}

const Orders = (_: RouteComponentProps) => {
  const { isLoggedIn } = useAccountContext()
  const [DataOrders, setDataorders] = useState<OnlineStoreOrderType | null>(
    null
  )

  const [store, setStore] = useState<StoreType>('online')
  const [loader, setLoader] = useState(true)

  const [currentPage, setcurrentPage] = useState(1)

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    const getOrderList = async () => {
      if (store !== 'online') {
        return
      }

      const endPoint = '/api/getOrderList'

      try {
        const { data } = await axios.post(endPoint, {
          page: currentPage,
        })

        const { list } = data.response.data.DktApp_GetOrderList

        setDataorders((oldData) => {
          return oldData
            ? { ...oldData, list: [...oldData.list, ...list] }
            : data.response.data.DktApp_GetOrderList
        })

        setLoader(false)
      } catch (error) {
        console.error(error)
      }
    }

    getOrderList()
  }, [isLoggedIn, store, currentPage])

  const PurchaseDelayAlert = () => {
    return (
      <div className="w-full bg-[#FFF1CC] mt-[32px]  p-[16px] flex items-center justify-center">
        <span className="text-[12px] font-semibold leading-5">
          Não está achando sua compra? Seu pedido pode demorar até 10 minutos
          para aparecer nesta lista
        </span>
      </div>
    )
  }

  return (
    <section className="w-full">
      <OrderTabs store={store} setStore={setStore} />

      {loader && !DataOrders ? (
        <NoOrdersSkeleton />
      ) : !DataOrders?.list.length ? (
        <>
          {store === 'online' && <PurchaseDelayAlert />}
          <NoOrders />
        </>
      ) : (
        <>
          {store === 'online' && <PurchaseDelayAlert />}
          <RenderOrders
            store={store}
            ListOrders={DataOrders}
            setValuePage={setcurrentPage}
            page={currentPage}
          />
        </>
      )}
    </section>
  )
}

export default Orders
